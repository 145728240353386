/** 3rd Party CSS */
@import url('bootstrap/dist/css/bootstrap.css');
@import url('@fortawesome/fontawesome-free/css/fontawesome.css');
@import url('@fortawesome/fontawesome-free/css/brands.css');
@import url('@fortawesome/fontawesome-free/css/solid.css');

/** Custom CSS */
@import url('../rev/css/rs6.css');
@import url('./fonts.css');
@import url('./theme-medicate.css');
@import url('./responsive.css');


/**
 * MARK: Overwrites of theme styles
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: unset;
}

a,
.button {
  color: var(--primary-dark-color);
}

.pq-section.pq-style-1 .pq-section-sub-title {
  background-color: var(--primary-color);
  color: var(--white-color);

  .pq-bg-primary & {
    background-color: var(--primary-dark-color);
  }
}

.pq-bg-primary {
  .pq-section.pq-style-1 .pq-section-title {
    color: var(--white-color);
  }

  .wysiwyg {

    &,
    & a {
      color: var(--white-color);
    }
  }

  .pq-button {
    background: var(--primary-dark-color);
  }
}

.pq-fancy-box-icon {
  display: flex;
}

.pq-fancy-box-icon svg {
  fill: var(--primary-color);
  margin: 0 auto;
  height: 77px;
  width: 50%;
  transition: all 0.5s ease;
}

.pq-fancy-box.pq-style-1:hover .pq-fancy-box-icon svg {
  transform: rotateY(180deg);
}

header#pq-header.pq-header-style-4 .pq-header-top-wrapper .pq-top-info-content .pq-header-info-box.address {
  width: auto;
}

header#pq-header.pq-header-style-4 .pq-header-top-wrapper .pq-top-info-content .pq-header-info-box:last-child {
  padding-left: 0;
}

.pq-header-menu {
  display: none;

  @media (max-width: 1023px) {
    display: block;
  }
}

.navbar {
  @media (max-width: 1023px) {
    justify-content: center;
  }
}

.navbar-brand {
  @media (max-width: 1023px) {
    margin-right: 0;
  }
}

main>section {
  padding: 0;

  &:last-child {
    padding-bottom: 10rem;
  }
}

.pq-section.pq-style-1.text-center {
  margin-bottom: 0;
}

.pq-footer-logo {
  filter: brightness(0) invert(1);
}

.owl-stage {
  display: flex;
}

.pq-testimonial-box.pq-style-1 {
  height: 100%;
}

.pq-bg-img-2:before {
  top: 0;
  background-position: top;
}

.pq-breadcrumb {
  .breadcrumb-bg {
    height: 100%;
    width: 100%;

    >img {
      height: 100%;
      object-fit: cover;
    }
  }

  /* <ol> tag */
  .breadcrumb {
    .breadcrumb-item+.breadcrumb-item:before {
      display: none;
    }

    li a,
    li i {
      color: var(--bs-body-color);
    }
  }
}

.pq-accordion-block .pq-accordion-box {
  border-radius: 3px;

  .pq-ad-title .ad-title-text,
  .pq-ad-title {
    transition: all 0.3s ease;
  }

  /* Adjust title if on grey background */
  .pq-bg-grey &:not(.pq-active) .pq-ad-title {
    background: var(--white-color);
  }

  /* Adjust title if on primary background */
  .pq-bg-primary &.pq-active .pq-ad-title {
    background: var(--primary-dark-color);
  }

  /* Adjust WYSIWYG if on primary background */
  .pq-bg-primary & .wysiwyg {
    color: var(--bs-body-color);
  }
}

.pq-bg-img-1:before {
  background-image: url(../images/bg-1.webp);
}

.pq-bg-img-2:before {
  background-image: url(../images/process/bg.webp);
}

/**
 * MARK: Dentist Hub Custom CSS
 */
.gap-none {
  padding: 0 0;
}

.gap-sm {
  padding: 0.5rem 0;
}

.gap-md {
  padding: 2rem 0;
}

.gap-lg {
  padding: 4rem 0;
}

.opening-hours {
  display: flex;
  justify-content: space-between;
}

.opening-hours__headline {
  color: var(--white-color);
}

.opening-hours__list {
  display: grid;
  margin: 0;
  padding: 0;
}

.opening-hours__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.opening-hours__label {
  font-weight: 700;
}

.opening-hours__text {
  margin-bottom: 0;
}

.legal-nav {
  display: flex;
}

.legal-nav li:first-child a {
  padding-left: 0 !important;
}

.breadcrumb-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.breadcrumb-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 36%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0));
}

.wysiwyg {
  a {
    text-decoration: underline;
  }
  .pq-button {
    text-decoration: none;
  }
}
